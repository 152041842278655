$blockName: 'pg-download';

.#{$blockName} {
  
  &__body {
    width: 100%;
    height: 100%;
  }
  &__bgimg {
    width: 100%;
    height: auto;
    margin-bottom: -8px;
    z-index: 1000;
  }
  &__bgtip {
    position: fixed;
    width: 172px;
    height: 62px;
    top: 5px;
    right: 10px;
    z-index: 1500;
    background-image: url('../../assets/images/OfficialWebsite/img_tips.png');
    background-size: 100% 100%;
    line-height: 16px;
    padding: 0 10px;
  }
  &__tip {
    position: relative;
    top:16px;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    letter-spacing: 1px;
    
  }
  &__browser {
    width: 15px;
    height: 15px;
    margin: 0 2px;
  }
  &__btn {
    width: 100%;
    height: 56px;
    background-color: #fff;
    border: 0;
    text-align: center;
    line-height: 56px;
    color: #FFAC43;
    font-size: 20px;
  }
  &__mask {
    // background: #000000;
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/Download/black.png');
    background-size: cover;
    opacity: 0.5;
    z-index: 1200;
  }
}