.test-wrap {
  width: 200px;
  height: 400px;
  background-color: yellow;
}

.item {
  width: 200px;
  height: 200px;
  background-color: red;
}