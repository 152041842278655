$blockName: 'pg-official-website';

.#{$blockName} {
  &__bg_img {
    width: 100%;
  }

  &__footer {
    background-color: rgba(255, 253, 233, 0.72);
    height: 50Px;
  }

  &__copyright {
    text-align: center;
    font-size: 12Px;
    font-weight: 700;
    line-height: 50Px;

    &>a {
      color: #A15B36;
      text-decoration: none;
      margin-right: 10Px;
    }

    &>img {
      width: 16Px;
      vertical-align: middle;
    }
  }
}